import './styles.css';

import { GatsbyBrowser } from 'gatsby';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = async ({
  location,
}) => {
  // Fetch the same page from the old side to populate logs for gatsby pages.
  await fetch('https://www2.knime.com' + location.pathname, {
    cache: 'no-cache',
    // mode: 'no-cors',
    headers: {
      'X-Referer': window.document.referrer,
      Referer: window.document.referrer,
    },
    referrerPolicy: 'unsafe-url',
  });
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = (
  args,
) => {
  // Tell Gatsby to only update scroll position if the pathname or hash has changed.
  // If only the search has changed (e.g. when a search form is submitted),
  // the scroll position should remain the same.
  const current = args.routerProps.location;
  const previous = args.prevRouterProps?.location;
  return (
    current.pathname !== previous?.pathname || current.hash !== previous?.hash
  );
};
